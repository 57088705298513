@import "rsuite/dist/rsuite.css";

.sidebar {
  overflow: hidden;
}

.bg-gradient-architecture {
  background : #AF1F25;
}

.sidebar .sidebar-brand{
  width: auto;
  height: auto;
}

.logo {
  padding: 10px;
  width: 90px;
  height: 90px;
}

.td-none {
  text-decoration: none;
}

.pr-170px {
  padding-right: 170px !important;
}

.mr-15 { 
  margin-right: 15px;
}

.p-cursor {
  cursor: pointer !important;
}

.modal.show {
  display: block;
}

.w-100pc {
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 20px;
}

a:hover, a:active, a:visited, a:focus {
  text-decoration: none;
}

#accordionSidebar a {
  color: #AF1F24;
  text-indent: 10px;
}

#accordionSidebar a:hover {
  background: rgba(242, 243, 246, 1);
  border-radius: 10px;
}

#accordionSidebar a:active, #accordionSidebar a:focus {
  background: rgba(175, 31, 36, 0.1);
  border-radius: 10px;
  font-weight: 600;
}

.accordion-button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.menuIcon {
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 10px;
}

.fg-theme {
  color: #AF1F24;
  font-weight: 600;
}

.btn-primary, .nav-pills .nav-link.active, .nav-pills .nav-link, .nav-pills .show>.nav-link {
  background-color: #ffffff;
  border-color: #AF1F24;
  outline: #AF1F24;
  color: #000;
}

.btn-primary:hover, .nav-pills .nav-link.active, .nav-pills .show>.nav-link:hover {
  background-color: #AF1F24;
  border-color: #AF1F24;
  outline: #AF1F24;
  color: #fff;
}

.btn-primary:active, .nav-pills .nav-link.active, .nav-pills .show>.nav-link:active {
  background-color: #AF1F24;
  border-color: #AF1F24;
  outline: #AF1F24;
  color: #fff;
}

.btn-primary:focus, .nav-pills .nav-link.active, .nav-pills .show>.nav-link:focus {
  background-color: #AF1F24;
  border-color: #AF1F24;
  outline: #AF1F24;
  color: #fff;
}

.btn .fas {
  color: inherit;
}

.form-select:focus, .form-select:active, .form-control:focus, .form-control:active {
  border-color: #b5b5b7;
  outline: 0;
  box-shadow: none;
}


.ml-15px {
  margin-left: 15px;
}
.ml-15 {
  margin-left: 15px;
}

.sweet-alert .confirm {
  background-color: #AF1F24 !important;
}

.sweet-alert .cancel {
  background-color: #000000 !important;
}

.sweet-alert p {
  font-size: 25px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.err-msg {
  color: red;
}

.step-container {
  display: none;
}

.step-container.active {
  display: block;
}

.navbar .nav-item a.nav-link {
  color: #ffffff !important;
}

.navbar .nav-item a.nav-link span {
  color: #ffffff !important;
}

.navbar .nav-item:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.navbar .nav-item a.nav-link:visited, 
.navbar .nav-item a.nav-link:focus, 
.navbar .nav-item a.nav-link:active, 
.navbar .nav-item a.nav-link:hover {
  background-color: transparent !important;
}

.navbar .nav-item a.nav-link span:visited, 
.navbar .nav-item a.nav-link span:focus, 
.navbar .nav-item a.nav-link span:active, 
.navbar .nav-item a.nav-link span:hover {
  background-color: transparent !important;
}

.mr-2 {
  margin-right: 10px;
}

.form-group {
  margin-top: 5px;
  margin-bottom: 5px;
}

.bg-login-image {
  background: url('./assets/dogs/image2.jpeg');
}

.h-100vh {
  height: 100vh;
}